import { useData } from "../../../../../context/data-context";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  Button,
  Calendar,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
  Table,
  TimePicker,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { useForm } from "antd/es/form/Form";

function EqueueModal() {
  const [formRef] = useForm();
  const dataValue = useData();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allDateEqueueInfo, setAllDateEqueueInfo] = useState([]);
  const [equeueCreatedFor, setEqueueCreatedFor] = useState([]);
  const [remarkModal, setRemarkModal] = useState(false);
  const [equeueDate, setEqueueDate] = useState(dayjs());
  const [editId, setEditId] = useState();
  const [lastEqueueData, setLastEqueueData] = useState();
  const [doctors, setDoctors] = useState([]);
  const storedData = dataValue?.dataState;
  const doctorInputValue = Form.useWatch("doctor", formRef);
  const { selectedDoctor, selectedBranch, openEqueueModal } = storedData;
  useEffect(() => {
    formRef.setFieldsValue({
      doctor_visiting_time: dayjs("10:00 AM", "h:mm A"),
      doctor_closing_time: dayjs("10:00 PM", "h:mm A"),
    });
    if (openEqueueModal && selectedBranch && selectedDoctor) {
      fecthLastEqueue({
        is_last: true,
        doctor: selectedDoctor,
        current_date: dayjs().format("YYYY-MM-DD"),
      });
      fetchDoctors({ roles: "4,7" });
    }
  }, [selectedBranch, selectedDoctor, openEqueueModal]);
  const fetchDoctors = (params = {}) => {
    axios
      .get(`apiV1/team/`, { params: { ...params } })
      .then((res) => {
        setDoctors(res?.data);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
      });
  };
  const disableDates = (currentDate) => {
    const today = dayjs().startOf("day"); // Get today's date
    // Disable dates from the array and past dates
    return (
      equeueCreatedFor.some((date) => currentDate.isSame(dayjs(date), "day")) ||
      currentDate.isBefore(today, "day")
    );
  };
  const fecthLastEqueue = (params = {}) => {
    axios
      .get(`/apiV1/e-queue/`, { params: { ...params } })
      .then((res) => {
        setLastEqueueData(res.data);

        let openModal = true;
        if (
          res?.data?.date &&
          dayjs().diff(dayjs(res?.data?.date), "days") == 0
        ) {
          openModal = false;
          dataValue.dispatch({
            type: "SET_STATE",
            payload: {
              key: "openEqueueModal",
              value: false,
            },
          });
          dataValue.dispatch({
            type: "SET_STATE",
            payload: {
              key: "todaysEqueueCreated",
              value: true,
            },
          });
        } else {
          openModal = true;
          formRef.setFieldsValue({
            limit: res?.data?.limit,
            average_time: res?.data?.average_time,
            doctor_visiting_time: dayjs(
              res?.data?.doctor_visiting_time ?? "00:01:00",
              "HH:mm"
            ),
            doctor_closing_time: dayjs(
              res?.data?.doctor_closing_time ?? "23:59:00",
              "HH:mm"
            ),
          });
          dataValue.dispatch({
            type: "SET_STATE",
            payload: {
              key: "todaysEqueueCreated",
              value: false,
            },
          });
        }
        setShowModal(openModal);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
      });
  };
  const onCloseModal = () => {
    setShowModal(false);
    dataValue.dispatch({
      type: "SET_STATE",
      payload: {
        key: "openEqueueModal",
        value: false,
      },
    });
    formRef.resetFields();
  };
  const addEqueue = (values) => {
    const formValues = {
      average_time: values?.average_time,
      doctor_visiting_time: values.doctor_visiting_time?.format("HH:mm"),
      doctor_closing_time: values.doctor_closing_time?.format("HH:mm"),
      expected_time: values.doctor_visiting_time?.format("HH:mm"),
      is_active: true,
      doctor: values?.doctor,
      branch: selectedBranch,
      limit: values?.limit,
      break_times: values.breaks?.map((slot) => ({
        start: slot.start_time.format("HH:mm:00"),
        end: slot.end_time.format("HH:mm:00"),
      })),
    };

    let formData = [];

    // Convert the dates to Date objects
    let startDate = dayjs(values.date[0]);
    let endDate = dayjs(values.date[1]);

    // Loop through each date between startDate and endDate
    for (
      let currentDate = startDate;
      currentDate.isBefore(endDate) || currentDate.isSame(endDate);
      currentDate = currentDate.add(1, "day")
    ) {
      let eachDayObj = {
        ...formValues,
        date: currentDate.format("YYYY-MM-DD"),
      };
      formData.push(eachDayObj);
    }

    axios
      .post("apiV1/create-equeue/", formData)
      .then((res) => {
        message.success("E-Queue created successfully!");
        onCloseModal();
        dataValue.dispatch({
          type: "SET_STATE",
          payload: {
            key: "todaysEqueueCreated",
            value: true,
          },
        });
      })
      .catch((err) => {
        message.error(err?.response?.data ?? "Something went wrong!");
      })
      .finally(() => {});
  };
  return (
    <Modal
      open={showModal}
      closeIcon={null}
      title={
        <Row align={"middle"} justify={"space-between"}>
          <Button size="small" onClick={onCloseModal}>
            Close
          </Button>
          <div>Add Equeue for today</div>
          <Button
            size="small"
            htmlType="submit"
            form="equeueForm"
            type="primary"
          >
            Submit
          </Button>
        </Row>
      }
      footer={null}
    >
      <Form
        form={formRef}
        layout="vertical"
        id="equeueForm"
        onFinish={addEqueue}
      >
        <Row gutter={24}>
          <Col md={12} sm={24}>
            <Form.Item
              name={"doctor"}
              label={"Doctor"}
              rules={[{ required: true, message: "Enter appointment limit!" }]}
            >
              <Select
                className="w-100"
                placeholder="Select Doctor"
                options={doctors?.map((each) => {
                  return {
                    label: each?.user?.name,
                    value: each?.registration?.doctor,
                  };
                })}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(input)
                }
              />
            </Form.Item>
          </Col>
          <Col md={12} sm={24}>
            <Form.Item
              name={"limit"}
              label={"Appointment Limit"}
              rules={[{ required: true, message: "Enter appointment limit!" }]}
            >
              <InputNumber className="w-100" min={1} />
            </Form.Item>
          </Col>
          <Col md={24}>
            <Form.Item
              name={"date"}
              label={"Select Date Range"}
              rules={[{ required: true, message: "Select a range!" }]}
            >
              <DatePicker.RangePicker
                className="w-100"
                disabledDate={disableDates}
                disabled={!doctorInputValue}
              />
            </Form.Item>
          </Col>

          <Col md={12} sm={24}>
            <Form.Item
              name={"average_time"}
              label={"Average Checkup Time"}
              rules={[
                { required: true, message: "Enter average checkup time!" },
              ]}
            >
              <InputNumber className="w-100" min={1} />
            </Form.Item>
          </Col>
          <Col md={12} sm={24}>
            <Form.Item
              name={"doctor_visiting_time"}
              label={"Doctor Visiting Time"}
              rules={[
                { required: true, message: "Enter doctor visiting time!" },
              ]}
            >
              <TimePicker use12Hours format="h:mm a" className="w-100" />
            </Form.Item>
          </Col>
          <Col md={12} sm={24}>
            <Form.Item
              name={"doctor_closing_time"}
              label={"Doctor Closing Time"}
              rules={[
                { required: true, message: "Enter doctor closing time!" },
              ]}
            >
              <TimePicker use12Hours format="h:mm a" className="w-100" />
            </Form.Item>
          </Col>
          <Col md={24}>
            <Form.Item label="Breaks">
              <Form.List name="breaks">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row key={key} gutter={16} align="top">
                        <Col md={10}>
                          <Form.Item
                            {...restField}
                            name={[name, "start_time"]}
                            rules={[
                              { required: true, message: "Enter start time!" },
                            ]}
                          >
                            <TimePicker
                              use12Hours
                              format="h:mm a"
                              className="w-100"
                              placeholder="Start Time"
                            />
                          </Form.Item>
                        </Col>
                        <Col md={10}>
                          <Form.Item
                            {...restField}
                            name={[name, "end_time"]}
                            rules={[
                              { required: true, message: "Enter end time!" },
                            ]}
                          >
                            <TimePicker
                              use12Hours
                              format="h:mm a"
                              className="w-100"
                              placeholder="End Time"
                            />
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          <Button
                            type="danger"
                            icon={<MinusCircleOutlined />}
                            onClick={() => remove(name)}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Break
                    </Button>
                  </>
                )}
              </Form.List>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default EqueueModal;
