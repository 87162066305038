import {
  PhoneOutlined,
  RightCircleFilled,
  RightOutlined,
  SearchOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  message,
  Radio,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useRef, useState } from "react";
import { useData } from "../../../../context/data-context";
import { useAuth } from "../../../../context/auth-context";
import axios from "axios";
import dayjs from "dayjs";
import { patientPrefixes } from "../../../utils/constants";
import { set } from "lodash";

export default function PatientForm({
  // handleNext,
  setPatientDetails,
  contact,
}) {
  const [formRef] = Form.useForm();
  const [avatar, setAvatar] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [medicalHistory, setMedicalHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataValue = useData();
  const authData = useAuth();
  const storedData = dataValue?.dataState;
  const currUserData = authData?.currUser;
  const [medicalHistoryInput, setMedicalHistoryInput] = useState("");
  const [patientList, setPatientList] = useState([]);
  const [patientLoading, setPatientLoading] = useState(false);
  const [searchContact, setSearchContact] = useState("");
  const [searchName, setSearchName] = useState("");
  const [newPatientForm, setNewPatientForm] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    setAvatar(file);
    let allowedExtension = ["image/jpeg", "image/jpg", "image/png"];
    let imgType = event.target?.files[0]?.type;
    if (allowedExtension.indexOf(imgType) === -1) {
      message.error("Only image(.jpeg, .jpg, .png) is acceptable!");
      return;
    }
    setSelectedImage(URL.createObjectURL(file));
  };
  const onSubmit = (values) => {
    const { selectedBranch } = storedData;
    setLoading(true);
    const payload = {
      ...values,
      medical_history: medicalHistory,
      branch: selectedBranch,
      dob: values.dob ? dayjs(values.dob).format("YYYY-MM-DD") : null,
    };
    var formData = new FormData();

    for (var key in payload) {
      if (!!payload[key]) {
        formData.append(key, payload[key]);
      }
    }
    if (avatar) {
      formData.append("avatar", avatar, avatar.name);
    }
    axios
      .post("/apiV1/clinic-patient/", formData)
      .then((res) => {
        setPatientDetails(res?.data);
        formRef.resetFields();
        // handleNext();
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
        console.log(err, "err");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearchPatient = (params = {}) => {
    if (searchContact?.length > 0) {
      setPatientLoading(true);
      axios
        .get(`/apiV1/patient-basic-info/`, { params: { ...params } })
        .then((res) => {
          setPatientList(res?.data?.result);
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.message ?? "Something went wrong!"
          );
        })
        .finally(() => {
          setPatientLoading(false);
        });
    } else {
      message.error("Search cannot be empty");
    }
  };

  useEffect(() => {
    if (searchContact?.length === 10) {
      handleSearchPatient({ contact: searchContact });
    }
  }, [searchContact]);
  useEffect(() => {
    if (searchName?.length > 0) {
      handleSearchPatient({ search: searchName, contact: searchContact });
    }
  }, [searchName]);

  useEffect(() => {
    const fetchCities = async () => {
      setLoadingCities(true);
      try {
        const response = await axios.get("/apiV1/clinic-patient-city/");
        setCityOptions(response.data?.result || []);
      } catch (error) {
        message.error("Failed to fetch cities");
      } finally {
        setLoadingCities(false);
      }
    };

    fetchCities();
  }, []);

  console.log(cityOptions, "cityOptions");

  return (
    <React.Fragment>
      {newPatientForm ? (
        <div>
          <Form
            onFinish={(values) => {
              // if (!values.age && !values.dob) {
              //   message.error("Either Age or Date of Birth is required!");
              //   return;
              // }
              onSubmit(values);
            }}
            form={formRef}
            id="patientForm"
            layout="vertical"
            initialValues={{
              contact: contact,
            }}
          >
            <Row gutter={24}>
              <Col className="py-2" md={24}>
                <Form.Item label={"Patient Image"}>
                  <div className="d-flex align-items-end">
                    {selectedImage ? (
                      <Avatar shape="square" src={selectedImage} size={80} />
                    ) : (
                      <Avatar
                        shape="square"
                        icon={<UserOutlined />}
                        size={80}
                      />
                    )}

                    {!selectedImage ? (
                      <div className="pl-3">
                        <div className="pb-1">No file uploaded</div>
                        <label
                          style={{ marginBottom: "0px" }}
                          htmlFor="profile"
                          role="button"
                          className="ant-btn ant-btn-primary px-3 py-1 text-white th-br-5"
                        >
                          Upload Image
                        </label>
                        <input
                          id="profile"
                          className="d-none"
                          type="file"
                          accept="image/png, image/jpg, image/jpeg"
                          onChange={handlePhotoChange}
                        />
                      </div>
                    ) : (
                      <div className="pl-3">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedImage(null);
                          }}
                          type="primary"
                        >
                          Remove Image
                        </Button>
                      </div>
                    )}
                  </div>
                </Form.Item>
              </Col>
              <Col md={4}>
                <Form.Item name={"prefix"} label={"Prefix"}>
                  <Select placeholder="Prefix" allowClear>
                    {patientPrefixes?.map(({ label, value }) => (
                      <Select.Option value={value}>{label}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name={"first_name"}
                  label={"First Name"}
                  rules={[
                    { required: true, message: "First name is required!" },
                    {
                      pattern: /^[A-Za-z\s!@#$%^&*(),.?"':{}|<>]+$/,
                      message:
                        "Only alphabets, spaces, and special characters are allowed!",
                    },
                  ]}
                >
                  <Input className="w-100" placeholder="First Name" />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name={"middle_name"}
                  label={"Middle Name"}
                  rules={[
                    {
                      pattern: /^[A-Za-z\s]+$/,
                      message: "Only alphabets are allowed!",
                    },
                  ]}
                >
                  <Input className="w-100" placeholder="Middle Name" />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name={"last_name"}
                  label={"Last Name"}
                  rules={[
                    {
                      pattern: /^[A-Za-z\s]+$/,
                      message: "Only alphabets are allowed!",
                    },
                  ]}
                >
                  <Input className="w-100" placeholder="Last Name" />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item name={"age"} label={"Age"}>
                  <InputNumber className="w-100" />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item name={"dob"} label={"Date of Birth"}>
                  <DatePicker format={"YYYY-MM-DD"} className="w-100" />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name={"gender"}
                  label={"Gender"}
                  // rules={[{ required: true, message: "Gender is required!" }]}
                >
                  <Radio.Group>
                    <Radio value="male">Male</Radio>
                    <Radio value="female">Female</Radio>
                    <Radio value="others">Prefer not to say</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name={"contact"}
                  label={"Whatsapp Number"}
                  rules={[
                    { required: true, message: "Whatsapp number is required!" },
                    {
                      pattern: /^\d{10}$/,
                      message: "Must be exactly 10 digits!",
                    },
                  ]}
                >
                  <Input
                    maxLength={10}
                    className="w-100"
                    placeholder="Whatsapp number"
                  />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name={"email"}
                  label={"Email Address"}
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address!",
                    },
                  ]}
                >
                  <Input className="w-100" placeholder="Email" />
                </Form.Item>
              </Col>
              {/* <Col md={8}>
                <Form.Item label="Primary Language">
                  <Select>
                    <Select.Option value="hindi">Hindi</Select.Option>
                    <Select.Option value="english">English</Select.Option>
                  </Select>
                </Form.Item>
              </Col> */}
              <Col md={8}>
                <Form.Item
                  // rules={[
                  //   { required: true, message: "City is required!" },
                  //   {
                  //     pattern: /^[A-Za-z\s]+$/,
                  //     message: "Only alphabets are allowed!",
                  //   },
                  // ]}
                  label="City"
                  name="city"
                >
                  <Input
                    className="w-100"
                    placeholder="City"
                    value={selectedCity}
                    onChange={(e) => {
                      setSelectedCity(e.target.value);
                      formRef.setFieldsValue({ city: e.target.value }); // Sync input with Form
                    }}
                  />
                  <div style={{ marginTop: "10px" }}>
                    {loadingCities ? (
                      <Spin />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                        }}
                      >
                        {cityOptions.map((city) => (
                          <Tag
                            key={city.id}
                            color={
                              selectedCity === city.name ? "blue" : "default"
                            }
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSelectedCity(city.name);
                              formRef.setFieldsValue({ city: city.name }); // Sync Tag selection with Form
                            }}
                          >
                            {city?.name}
                          </Tag>
                        ))}
                      </div>
                    )}
                  </div>
                </Form.Item>
              </Col>

              <Col md={8}>
                <Form.Item
                  label="Address"
                  name="address"
                  rules={[
                    {
                      max: 200,
                      message: "Address cannot exceed 200 characters!",
                    },
                  ]}
                >
                  <TextArea rows={3} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className="d-flex justify-content-end pt-3">
            <Button
              form="patientForm"
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              Next <RightOutlined />
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <Row gutter={[14, 10]} className="mt-3">
            <Col span={searchContact?.length === 10 ? 12 : 24}>
              <Input
                className="w-100"
                size="large"
                placeholder="Search by mobile"
                prefix={<PhoneOutlined rotate={90} />}
                maxLength={10}
                onChange={(e) => {
                  setSearchContact(e.target.value);
                }}
              />
            </Col>
            {searchContact?.length === 10 && (
              <Col span={12}>
                <Input
                  className="w-100"
                  size="large"
                  placeholder="Patient Name"
                  prefix={<UserOutlined />}
                  onChange={(e) => {
                    setSearchName(e.target.value);
                  }}
                />
              </Col>
            )}
          </Row>

          <div className="mt-3">
            {patientLoading ? (
              <div className="d-flex justify-content-center my-5 w-100">
                <Spin size="large" />
              </div>
            ) : patientList?.length > 0 ? (
              <>
                <div
                  className="th-hidden-scrollbar"
                  style={{
                    maxHeight: "400px",
                    overflowY: "scroll",
                    position: "relative",
                  }}
                >
                  {patientList?.map((each) => (
                    <Row
                      className="mb-2 p-2 border th-br-5 th-pointer w-100"
                      onClick={() => {
                        setPatientDetails({
                          id: each?.id,
                          last_appointment: each?.last_appointment,
                        });
                      }}
                      justify={"space-between"}
                      align={"middle"}
                    >
                      <Space>
                        <Avatar src={each?.avatar} />

                        <div className="">
                          <div className="">
                            {each?.first_name ?? ""} {each?.middle_name ?? ""}{" "}
                            {each?.last_name ?? ""} (
                            {each?.gender?.substring(0, 1)?.toUpperCase()}){" "}
                          </div>
                          <div className="">{each?.contact}</div>
                        </div>
                      </Space>
                      <RightCircleFilled
                        className="th-primary"
                        style={{ fontSize: "20px" }}
                      />
                    </Row>
                  ))}
                </div>
              </>
            ) : (
              <div className="mx-auto mt-4">
                <Empty description="No Patients" />
                {searchContact.length === 10 && (
                  <div className="text-center">
                    <Button
                      className="mt-2 mx-auto"
                      onClick={(e) => {
                        e.preventDefault();
                        setNewPatientForm(true);
                        formRef.setFieldsValue({
                          contact: searchContact,
                          first_name: searchName,
                        });
                      }}
                      type="primary"
                      size="small"
                    >
                      Create New
                    </Button>
                  </div>
                )}
              </div>
            )}
            {patientList?.length > 0 && searchContact.length === 10 && (
              <div
                className=" mt-2 mb-2 p-2 border th-br-5 th-pointer bg-white w-100 d-flex justify-content-between align-items-center  "
                onClick={(e) => {
                  e.preventDefault();
                  setNewPatientForm(true);
                  formRef.setFieldsValue({
                    contact: searchContact,
                    first_name: searchName,
                  });
                }}
                style={{ bottom: 0 }}
              >
                <Space>
                  <Avatar icon={<UserAddOutlined />} />
                  <div className="">
                    <div className="">
                      {searchName?.length > 0 && searchName}
                    </div>
                    <div className="">
                      {searchContact?.length > 0 && searchContact}
                    </div>
                  </div>
                </Space>
                <Tag className="th-14 py-1" color="blue">
                  Create New <RightOutlined />
                </Tag>
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
