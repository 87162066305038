import React, { useEffect, useState } from "react";
import {
  CalendarOutlined,
  FilterOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Button, Avatar, Dropdown, Space, Drawer } from "antd";
import { useAuth } from "../../../../../context/auth-context";
import { useData } from "../../../../../context/data-context";
import Branch from "./Branch";
import { Link } from "react-router-dom";

const { Header } = Layout;

const Topbar = ({ colorBgContainer, setCollapsed, collapsed, modalOpen }) => {
  const authValue = useAuth();
  const currUserData = authValue?.currUser;
  const [filterOpen, setFilterOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (date) => {
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const ampm = date.getHours() >= 12 ? "PM" : "AM";

    const day = date.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    const dayWithSuffix =
      day +
      (["th", "st", "nd", "rd"][
        day % 10 > 3 || Math.floor((day % 100) / 10) === 1 ? 0 : day % 10
      ] || "th");
    // Date: ${dayWithSuffix} ${month} ${year} - Time:
    return `${hours}:${minutes}:${seconds} ${ampm}`;
  };

  const menu = [
    {
      key: 1,
      label: (
        <div>
          <div className="th-fw-500">{authValue?.currUser?.name}</div>
          <div className="th-12">{authValue?.currUser?.email}</div>
        </div>
      ),
    },
    {
      key: 2,
      label: (
        <div className="d-flex justify-content-between th-12">
          <Link to={`/my-account`} className="th-primary">
            My Account
          </Link>
          <div onClick={authValue?.logoutHandler} className="text-danger">
            <LogoutOutlined /> Sign Out
          </div>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Header
        className="d-flex justify-content-between align-items-center px-3"
        style={{
          padding: 0,
          background: colorBgContainer,
        }}
      >
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={{
            fontSize: "16px",
            width: 64,
            height: 64,
          }}
        />
        <div className="d-flex align-items-center">
          <div
            className="th-12"
            style={{ marginRight: "16px", fontWeight: 500 }}
          >
            <div>
              <b>{formatTime(currentTime)}</b>
            </div>
          </div>
          <Space>
            {currUserData?.roles?.id !== 1 && (
              <>
                <div className="d-none d-md-block">
                  <Branch width={180} />
                </div>
                <div className="d-block d-md-none">
                  <Avatar
                    onClick={() => {
                      setFilterOpen(true);
                    }}
                    style={{ backgroundColor: "tomato" }}
                    size={35}
                    icon={<FilterOutlined />}
                  />
                </div>
              </>
            )}
            {currUserData?.roles?.id !== 3 && (
              <>
                <div className="d-none d-md-block">
                  <Button
                    onClick={modalOpen}
                    type="primary"
                    icon={<CalendarOutlined />}
                  >
                    Add Appointment
                  </Button>
                </div>
                <div className="d-block d-md-none">
                  <Avatar
                    onClick={modalOpen}
                    type="primary"
                    style={{ backgroundColor: "#293648" }}
                    icon={<CalendarOutlined />}
                  />
                </div>
              </>
            )}
            <Dropdown
              menu={{
                items: menu,
              }}
              trigger={["click"]}
              placement="bottomLeft"
            >
              <div style={{ padding: "4px 15px" }} role="button">
                <Avatar
                  size={35}
                  style={{ backgroundColor: "#87d068" }}
                  icon={<UserOutlined />}
                />
              </div>
            </Dropdown>
          </Space>
        </div>
      </Header>
      <Drawer
        open={filterOpen}
        onClose={() => {
          setFilterOpen(false);
        }}
        placement="top"
        title="Filters"
        closeIcon={null}
      >
        <Space direction="vertical" className="w-100">
          <Branch width={"100%"} />
        </Space>
      </Drawer>
    </React.Fragment>
  );
};

export default Topbar;
