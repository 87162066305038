import {
  Button,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  TimePicker,
  message,
} from "antd";
import { useForm } from "antd/es/form/Form";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useData } from "../../../context/data-context";
import dayjs from "dayjs";
import {
  YoutubeFilled,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

const ExplicitEqueueModal = ({
  open,
  onCloseModal,
  callbackFunc,
  preSelectedDoctor,
}) => {
  const dataValue = useData();
  const storedData = dataValue?.dataState;
  const { selectedDoctor, selectedBranch } = storedData;
  const [equeueCreatedFor, setEqueueCreatedFor] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [formRef] = useForm();
  const doctorInputValue = Form.useWatch("doctor", formRef);

  useEffect(() => {
    fetchDoctors({ roles: "4,7" });
    fecthLastEqueue({ is_last: true });
  }, []);

  useEffect(() => {
    if (doctorInputValue) {
      formRef.resetFields(["date"]);
      fetchEQueueInfo();
    }
  }, [doctorInputValue]);

  const fetchEQueueInfo = () => {
    axios
      .get(`/apiV1/e-queue/`)
      .then((res) => {
        setEqueueCreatedFor(
          res?.data
            ?.filter((e) => e?.doctor == doctorInputValue)
            ?.map((each) => each?.date)
        );
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
      });
  };

  const fecthLastEqueue = (params = {}) => {
    axios
      .get(`/apiV1/e-queue/`, { params: { ...params } })
      .then((res) => {
        formRef.setFieldsValue({
          limit: res?.data?.limit,
          average_time: res?.data?.average_time,
          doctor_visiting_time: dayjs(
            res?.data?.doctor_visiting_time ?? "10:00:00",
            "HH:mm"
          ),
          doctor_closing_time: dayjs(
            res?.data?.doctor_closing_time ?? "22:00:00",
            "HH:mm"
          ),
          doctor: preSelectedDoctor ?? null,
        });
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
      });
  };

  const fetchDoctors = (params = {}) => {
    axios
      .get(`apiV1/team/`, { params: { ...params } })
      .then((res) => {
        setDoctors(res?.data);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
      });
  };

  const disableDates = (currentDate) => {
    const today = dayjs().startOf("day");
    return (
      equeueCreatedFor.some((date) => currentDate.isSame(dayjs(date), "day")) ||
      currentDate.isBefore(today, "day")
    );
  };

  const addEqueue = (values) => {
    const formValues = {
      average_time: values?.average_time,
      doctor_visiting_time: values.doctor_visiting_time?.format("HH:mm"),
      doctor_closing_time: values.doctor_closing_time?.format("HH:mm"),
      expected_time: values.doctor_visiting_time?.format("HH:mm"),
      is_active: true,
      doctor: values?.doctor,
      branch: selectedBranch,
      limit: values?.limit,
      break_times: values.breaks?.map((slot) => ({
        start: slot.start_time.format("HH:mm:00"),
        end: slot.end_time.format("HH:mm:00"),
      })),
    };

    let formData = [];

    let startDate = dayjs(values.date[0]);
    let endDate = dayjs(values.date[1]);

    for (
      let currentDate = startDate;
      currentDate.isBefore(endDate) || currentDate.isSame(endDate);
      currentDate = currentDate.add(1, "day")
    ) {
      let eachDayObj = {
        ...formValues,
        date: currentDate.format("YYYY-MM-DD"),
      };
      formData.push(eachDayObj);
    }

    axios
      .post("apiV1/create-equeue/", formData)
      .then((res) => {
        message.success("E-Queue created successfully!");
        if (callbackFunc) {
          callbackFunc();
        }
        onCloseModal();
        formRef.resetFields();
      })
      .catch((err) => {
        message.error(err?.response?.data ?? "Something went wrong!");
      });
  };

  return (
    <Modal
      open={open}
      closeIcon={null}
      title={
        <Row align={"middle"} justify={"space-between"} gutter={[0, 10]}>
          <Button
            size="small"
            onClick={() => {
              onCloseModal();
              formRef.resetFields();
            }}
          >
            Close
          </Button>
          <div>Add Equeue</div>
          <div style={{ fontSize: "14px" }} className="ml-2">
            <a
              href="https://www.youtube.com/watch?v=rqVtrgvESj4&t=11s"
              target="__blank"
            >
              <YoutubeFilled style={{ color: "#FE0000" }} /> Need Help?
            </a>
          </div>
        </Row>
      }
      footer={
        <Button size="small" htmlType="submit" form="equeueForm" type="primary">
          Submit
        </Button>
      }
    >
      <Form
        form={formRef}
        layout="vertical"
        id="equeueForm"
        onFinish={addEqueue}
        className="mt-3"
      >
        <Row gutter={24}>
          {/* Doctor */}
          <Col md={12} sm={24}>
            <Form.Item
              name={"doctor"}
              label={"Doctor"}
              rules={[{ required: true, message: "Select Doctor!" }]}
            >
              <Select
                className="w-100"
                placeholder="Select Doctor"
                options={doctors?.map((each) => ({
                  label: each?.registration?.name,
                  value: each?.registration?.doctor,
                  key: each?.registration?.doctor,
                }))}
                showSearch
              />
            </Form.Item>
          </Col>

          {/* Appointment Limit */}
          <Col md={12} sm={24}>
            <Form.Item
              name={"limit"}
              label={"Appointment Limit"}
              rules={[{ required: true, message: "Enter appointment limit!" }]}
            >
              <InputNumber className="w-100" min={1} />
            </Form.Item>
          </Col>

          {/* Date Range */}
          <Col md={24}>
            <Form.Item
              name={"date"}
              label={"Select Date Range"}
              rules={[{ required: true, message: "Select a range!" }]}
            >
              <DatePicker.RangePicker
                className="w-100"
                disabledDate={disableDates}
                disabled={!doctorInputValue}
              />
            </Form.Item>
          </Col>

          {/* Average Time */}
          <Col md={12} sm={24}>
            <Form.Item
              name={"average_time"}
              label={"Average Checkup Time"}
              rules={[
                { required: true, message: "Enter average checkup time!" },
              ]}
            >
              <InputNumber className="w-100" min={1} />
            </Form.Item>
          </Col>

          {/* Visiting Time */}
          <Col md={12} sm={24}>
            <Form.Item
              name={"doctor_visiting_time"}
              label={"Doctor Visiting Time"}
              rules={[
                { required: true, message: "Enter doctor visiting time!" },
              ]}
            >
              <TimePicker use12Hours format="h:mm a" className="w-100" />
            </Form.Item>
          </Col>

          {/* Closing Time */}
          <Col md={12} sm={24}>
            <Form.Item
              name={"doctor_closing_time"}
              label={"Doctor Closing Time"}
              rules={[
                { required: true, message: "Enter doctor closing time!" },
              ]}
            >
              <TimePicker use12Hours format="h:mm a" className="w-100" />
            </Form.Item>
          </Col>

          {/* Breaks Section */}
          <Col md={24}>
            <Form.Item label="Breaks">
              <Form.List name="breaks">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row key={key} gutter={16} align="top">
                        <Col md={10}>
                          <Form.Item
                            {...restField}
                            name={[name, "start_time"]}
                            rules={[
                              { required: true, message: "Enter start time!" },
                            ]}
                          >
                            <TimePicker
                              use12Hours
                              format="h:mm a"
                              className="w-100"
                              placeholder="Start Time"
                            />
                          </Form.Item>
                        </Col>
                        <Col md={10}>
                          <Form.Item
                            {...restField}
                            name={[name, "end_time"]}
                            rules={[
                              { required: true, message: "Enter end time!" },
                            ]}
                          >
                            <TimePicker
                              use12Hours
                              format="h:mm a"
                              className="w-100"
                              placeholder="End Time"
                            />
                          </Form.Item>
                        </Col>
                        <Col md={4}>
                          <Button
                            type="danger"
                            icon={<MinusCircleOutlined />}
                            onClick={() => remove(name)}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Break
                    </Button>
                  </>
                )}
              </Form.List>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ExplicitEqueueModal;
