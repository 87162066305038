export const dateFilters = [
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Week",
    value: "week",
  },
  {
    label: "Month",
    value: "month",
  },
  {
    label: "Date Range",
    value: "date-range",
  },
];

export const dashboardChartTitle = {
  today: "Todays Count",
  week: "Weekly Count",
  month: "Monthly Count",
};

export const generateUniqueIntId = () => {
  return Date.now() + Math.floor(Math.random() * 10000);
};

export const followupOptions = [
  {
    value: 3,
    label: "3 Days",
  },
  {
    value: 5,
    label: "5 Days",
  },
  {
    value: 7,
    label: "1 Week",
  },
  {
    value: 14,
    label: "2 Weeks",
  },
  {
    value: 30,
    label: "1 Month",
  },
  {
    value: 45,
    label: "45 Days",
  },
  {
    value: 90,
    label: "3 Months",
  },
  {
    value: 180,
    label: "6 Months",
  },
  {
    value: -1,
    label: "Select Date",
  },
];

export const patientPrefixes = [
  {
    label: "M/B/O",
    value: "mbo",
  },
  {
    label: "F/B/O",
    value: "fbo",
  },
];

export const mapPatientPrefix = {
  mbo: "M/B/O",
  fbo: "F/B/O",
};
