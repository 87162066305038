import {
  CloseCircleOutlined,
  LeftOutlined,
  LoadingOutlined,
  PlusOutlined,
  RightCircleFilled,
  SearchOutlined,
  YoutubeFilled,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Empty,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Steps,
  Tabs,
  message,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import PatientForm from "./PatientForm";
import "./index.css";
import AppointmentForm from "./AppointmentForm";
import MyDesktop from "../UtilComponents/MyDesktop";
import axios from "axios";
import TabPane from "antd/es/tabs/TabPane";
import { useNavigate } from "react-router-dom";
export default function AppointmentModal({ modalVisible, closeModal }) {
  const [currentStep, setCurrentStep] = useState(0);
  const [patientDetails, setPatientDetails] = useState(null);
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState();
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState();
  const [patientList, setPatientList] = useState([]);
  const [patientLoading, setPatientLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);

  const [activeTab, setActiveTab] = useState("1");

  const navigate = useNavigate();

  useEffect(() => {
    fetchDoctors({ roles: "4,7" });
  }, []);

  const handleButtonClick = () => {
    // Switch to the second tab when the button is clicked
    setActiveTab("2");
  };

  const handleClose = () => {
    setCurrentStep(0);
    closeModal();
    setSearch("");
    setSelectedPatient(false);
    setPatientList(false);
    setPatientDetails(null);
    setSelectedDoctor();
  };
  const handleSearchPatient = (params = {}) => {
    if (search?.length > 0) {
      setPatientLoading(true);
      axios
        .get(`/apiV1/patient-basic-info/`, { params: { ...params } })
        .then((res) => {
          setPatientList(res?.data?.result);
          setSearchText(search);
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.message ?? "Something went wrong!"
          );
        })
        .finally(() => {
          setPatientLoading(false);
        });
    } else {
      message.error("Search cannot be empty");
    }
  };

  const fetchDoctors = (params = {}) => {
    axios
      .get(`apiV1/team/`, { params: { ...params } })
      .then((res) => {
        setDoctors(res?.data);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
      });
  };
  const [doctorLoading, setDoctorLoading] = useState({ show: false, id: null });
  const handleSelectDoctor = (doctor) => {
    setDoctorLoading({
      show: true,
      id: doctor?.id,
    });
    axios
      .get(`apiV1/doctor-charge/`, {
        params: { doctor: doctor?.registration?.doctor },
      })
      .then((res) => {
        if (res?.data?.first_time_charge && res?.data?.returning_charge) {
          setSelectedDoctor({
            ...doctor,
            returning_expire_duration: res?.data?.returning_expire_duration,
            first_time_charge: res?.data?.first_time_charge,
            returning_charge: res?.data?.returning_charge,
          });
        } else {
          notification.error({
            message: (
              <div>
                <div>No charges defined for this doctor!</div>
                <Button
                  className="mt-3"
                  icon={<PlusOutlined />}
                  type="primary"
                  onClick={() => {
                    closeModal();
                    navigate(`/config/doctor-profile/profession-fees`);
                  }}
                >
                  Add Fees
                </Button>
              </div>
            ),
          });
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
      })
      .finally(() => {
        setDoctorLoading({
          show: false,
          id: null,
        });
      });
  };
  return (
    <React.Fragment>
      <div className="">
        <Modal
          open={modalVisible}
          closable={null}
          style={{
            top: "20px",
          }}
          closeIcon={
            <CloseCircleOutlined
              style={{ fontSize: "25px" }}
              onClick={handleClose}
            />
          }
          title={
            <div>
              {activeStep !== 1 && (
                <LeftOutlined
                  className="mr-2"
                  onClick={() => {
                    if (activeStep === 2) {
                      setSelectedDoctor(null);
                      setSelectedPatient(null);
                    } else if (activeStep === 3) {
                      setSelectedDoctor(null);
                    }
                    setActiveStep((prev) => prev - 1);
                  }}
                />
              )}
              Add Appointment
              <div style={{ fontSize: "14px", marginTop: "5px" }} className="">
                <a
                  href="https://www.youtube.com/watch?v=L1BVKADpIWg&t=3s"
                  target="__blank"
                  className=""
                >
                  <YoutubeFilled className="" style={{ color: "#FE0000" }} />{" "}
                  Need Help?
                </a>
              </div>
            </div>
          }
          footer={null}
          width={window.innerWidth > 700 ? 700 : window.innerWidth - 10}
          centered={false}
          className="th-full-screen-modal"
        >
          {selectedPatient ? (
            selectedDoctor ? (
              <div
                className="th-hidden-scrollbar"
                style={{
                  maxHeight: "490px",
                  overflowY: "scroll",
                }}
              >
                <AppointmentForm
                  patientDetails={patientDetails}
                  closeModal={handleClose}
                  selectedDoctor={selectedDoctor}
                />
              </div>
            ) : (
              <div>
                <div className="th-16 th-fw-500 mb-2">Select Doctor</div>
                {doctors?.length > 0 ? (
                  doctors?.map((each) => {
                    let avatar = each?.user?.name?.split(" ");
                    return (
                      <Row
                        className="mb-2 p-2 border th-br-5 th-pointer w-100"
                        onClick={() => {
                          handleSelectDoctor(each);
                          setActiveStep(3);
                        }}
                        justify={"space-between"}
                        align={"middle"}
                      >
                        <Space>
                          <Avatar className="mr-2" src={each?.user?.avatar}>
                            {!each?.user?.avatar &&
                              [
                                avatar[0]?.substring(0, 1)?.toUpperCase(),
                                avatar[avatar.length - 1]
                                  ?.substring(0, 1)
                                  ?.toUpperCase() ?? "",
                              ]?.join("")}
                          </Avatar>
                          <div className="">
                            <div className="">
                              Dr. {each?.registration?.name}
                            </div>
                            <div className="th-12">{each?.user?.contact}</div>
                          </div>
                        </Space>
                        {doctorLoading?.show &&
                        each?.id == doctorLoading?.id ? (
                          <LoadingOutlined
                            className="th-primary"
                            style={{ fontSize: "20px" }}
                          />
                        ) : (
                          <RightCircleFilled
                            className="th-primary"
                            style={{ fontSize: "20px" }}
                          />
                        )}
                      </Row>
                    );
                  })
                ) : (
                  <Empty description="No doctors in branch" />
                )}
              </div>
            )
          ) : (
            <PatientForm
              setPatientDetails={(value) => {
                setSelectedPatient(true);
                setPatientDetails(value);
                setActiveStep((prev) => prev + 1);
                if (doctors && doctors.length == 1) {
                  handleSelectDoctor(doctors[0]);
                }
              }}
            />
          )}
        </Modal>
      </div>
    </React.Fragment>
  );
}
