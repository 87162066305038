import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  message,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/auth-context";
import { Navigate } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MyDesktop from "../../component/UtilComponents/MyDesktop";
import { YoutubeFilled } from "@ant-design/icons";

const LoginPage = () => {
  const authValue = useAuth();
  const { loginHandler, loginLoading, otpHandler } = authValue;

  const [loading, setLoading] = useState(false);

  // otp states
  const [loginOTP, setLoginOTP] = useState(false);
  const [OTPRecieved, setOTPRecieved] = useState(false);

  const [seconds, setSeconds] = useState(300);
  const [isActive, setIsActive] = useState(false);

  const [register, setRegister] = useState(false);

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    infinite: true,
    arrows: false,
  };

  useEffect(() => {
    let interval = null;
    if (isActive) {
      if (seconds > 0) {
        interval = setInterval(() => {
          setSeconds((seconds) => seconds - 1);
        }, 1000);
      } else {
        setSeconds(0);
        setIsActive(false);
      }
    } else if (!isActive && seconds !== 300) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const resendOTP = (contact) => {
    axios
      .post(`/apiV1/generate-otp/`, { contact: contact, method: "login" })
      .then((res) => {
        setOTPRecieved((prev) => true);
        setSeconds(300);
        setIsActive(true);
        message.success("OTP sent");
      })
      .catch((err) => {
        notification.error({
          message: err.response.data?.message ?? "Something went wrong!",
        });
      });
  };

  const onFinish = (values) => {
    if (loginOTP) {
      if (OTPRecieved) {
        if (values?.otp) {
          let authorized1 = false;
          axios
            .post(`apiV1/verify-otp/`, {
              contact: values?.contact,
              otp: values?.otp,
              method: "login",
            })
            .then((response) => {
              authorized1 = true;
              const token = response?.data?.token?.toString();
              const expirationDate = new Date(
                new Date().getTime() + 3600 * 10000
              );
              localStorage.setItem("token", token);
              localStorage.setItem("expirationDate", expirationDate);
              otpHandler(token);
            })
            .catch((error) => {
              message.error(
                `${error.response.data?.message ?? "Something went wrong!"}`
              );
            })
            .finally(() => {
              setLoading(false);
            });
        }
      } else {
        if (values?.contact) {
          resendOTP(values?.contact);
        }
      }
    } else {
      loginHandler(values);
    }
  };

  const onRegister = (values) => {
    console.log(values, "values");
    if (values?.password !== values.confirm_password) {
      message.error("Confirm Password is not same as Password");
      return;
    }
    values.roles = 8;
    axios
      .post("apiV1/user/", values)
      .then((res) => {
        console.log(res?.data);
        message.success("User successfully Created");
        loginHandler(values);
      })
      .catch((err) => {
        console.log(err?.message);
        message.error("user already exists with this email or Contact");
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  if (authValue?.token) {
    if (authValue?.currUser?.default_branch) {
      return <Navigate to={"/"} />;
    }
    return <Navigate to={"/select-office"} />;
  }

  let divisor_for_minutes = seconds % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);
  let divisor_for_seconds = divisor_for_minutes % 60;
  let second = Math.ceil(divisor_for_seconds);

  const slider_images = [
      "https://d10xay0j85xemm.cloudfront.net/utils/login_image_1.png",
      "https://d10xay0j85xemm.cloudfront.net/utils/login_image_2.png",
      "https://d10xay0j85xemm.cloudfront.net/utils/login_image_3.png",
      "https://d10xay0j85xemm.cloudfront.net/utils/login_image_4.png",
    ]

  return (
    <Row
      className="bg-white"
      style={{ minHeight: "100vh", overflowY: "hidden" }}
    >
      <Col md={12} xs={24}>
        <div className="th-flex-center" style={{ height: "100vh" }}>
          {!register ? (
            <div 
            // style={{ minWidth: "25.33rem", padding: "1rem" }}
            style={{ maxWidth:"420px" }}
            className=" w-100  p-3 p-sm-4"
            >                
              <div className="d-flex justify-content-between">
                <h3>Sign In</h3>
                <a
                  href="https://www.youtube.com/watch?v=PDLNsprFQRg"
                  target="__blank"
                >
                  <YoutubeFilled
                    className="th-16"
                    style={{ color: "#FE0000" }}
                  />{" "}
                  Need Help?
                </a>
              </div>
              <p className="text-secondary pt-1">
                {!loginOTP ? "Login and password" : "With Contact"}
              </p>
              {!loginOTP ? (
                <Form
                // className="bg-success"
                  name="loginForm"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout="vertical"
                >
                  <Form.Item
                    label="Contact"
                    name="contact"
                    rules={[
                      { required: true, message: "Please input your contact!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="w-100"
                      loading={loginLoading}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <Form
                  name="loginForm"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout="vertical"
                >
                  <Form.Item
                    label="Contact"
                    name="contact"
                    rules={[
                      { required: true, message: "Please input your contact!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  {OTPRecieved && (
                    <Form.Item
                      label="otp"
                      name="otp"
                      rules={[
                        { required: true, message: "Please input your otp!" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="w-100"
                      loading={loginLoading}
                    >
                      {loginOTP
                        ? OTPRecieved
                          ? "Login"
                          : "Get OTP"
                        : !authValue?.loginLoading
                        ? "Login"
                        : "Logging in..."}
                    </Button>
                  </Form.Item>
                </Form>
              )}
              <div
                className="th-pointer"
                onClick={() => {
                  setLoginOTP((prevState) => !prevState);
                  setOTPRecieved(false);
                }}
              >
                Want to sign in with{" "}
                <span>{!loginOTP ? "OTP" : "password"}</span> ?
              </div>
              {OTPRecieved && loginOTP && (
                <div className="col-md-12">
                  <div className="d-flex justify-content-between align-items-center pl-0 mt-3">
                    <Button
                      type="primary"
                      disabled={seconds > 0}
                      onClick={resendOTP}
                      htmlType="submit"
                      className="w-50"
                      loading={loginLoading}
                    >
                      Resend OTP
                    </Button>
                    {seconds > 0 && (
                      <div className="font-size-16">
                        {minutes}:{second < 10 ? "0" + second : second}
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Divider />
                <p>Don't have an account ?</p>
                <Button className="w-100" onClick={() => setRegister(true)}>
                  Register for new account
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <div
              //  style={{ width: "25.33rem", padding: "1rem" }}
                 style={{ maxWidth:"420px" }}
            className=" w-100  p-3 p-sm-4"
               >
                <div className="d-flex justify-content-between">
                  <h3 className="mb-1 mt-lg-5">Register</h3>
                  <a
                    href="https://www.youtube.com/watch?v=PDLNsprFQRg"
                    target="__blank"
                  >
                    <YoutubeFilled
                      className="th-16"
                      style={{ color: "#FE0000" }}
                    />{" "}
                    Need Help?
                  </a>
                </div>
                <Form
                  name="registerForm"
                  initialValues={{ remember: true }}
                  onFinish={onRegister}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout="vertical"
                >
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      { required: true, message: "Please input your contact!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: true, message: "Please input your contact!" },
                      {
                        type: "email",
                        message: "Please enter a valid email address!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Contact"
                    name="contact"
                    rules={[
                      { required: true, message: "Please input your contact!" },
                      {
                        pattern: /^\d{10}$/,
                        message: "Please enter a valid 10-digit number",
                      },
                      {
                        len: 10,
                        message: "Contact number must be 10 digits long",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="password"
                    extra="Password Requirements: At least 8 characters, One uppercase letter (A-Z), One lowercase letter (a-z), One number (0-9), One special character (@, $, !, %, *, ?, &)"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                      {
                        pattern:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        message:
                          "Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character.",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Enter your password (e.g., Password@123)" />
                  </Form.Item>

                  <Form.Item
                    label="Confirm Password"
                    name="confirm_password"
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="w-100"
                      loading={loginLoading}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
                <div
                  className="th-pointer"
                  onClick={() => {
                    setRegister(false);
                  }}
                >
                  Login
                </div>
              </div>
            </div>
          )}
        </div>
      </Col>
      <MyDesktop>
      <Col md={12} xs={24} className="">
        <Slider {...settings} className="d-flex justify-content-center">
          {slider_images.map((url, index) => (
            <div className="p-2" key={index}>
              <img src={url} alt={`Slide ${index + 1}`} style={{ width: "100%" }} />
            </div>
          ))}
        </Slider>
      </Col>

      </MyDesktop>
    </Row>
  );
};

export default LoginPage;
