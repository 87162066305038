import React, { useEffect, useState } from "react";
import { Breadcrumb, FloatButton, Layout, Modal, theme } from "antd";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import Footer from "./Footer";
import { CalendarOutlined } from "@ant-design/icons";
import AppointmentModal from "../../AppointmentModal";
import { useAuth } from "../../../../context/auth-context";
import { Outlet, useLocation } from "react-router-dom";
import EqueueModal from "./EqueueModal";
import { useData } from "../../../../context/data-context";

const { Content } = Layout;
function ChatwootScript() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/config") {
      const existingScript = document.getElementById("chatwoot-script");
      if (existingScript) {
        return;
      }
      const existingDiv = document.querySelector(".woot--bubble-holder");
      if (existingDiv) {
        existingDiv.style.visibility = "visible";
      }
      const script = document.createElement("script");
      script.id = "chatwoot-script";
      script.src = "https://app.chatwoot.com/packs/js/sdk.js";
      script.defer = true;
      script.async = true;

      script.onload = function () {
        window.chatwootSDK.run({
          websiteToken: "W6j2iQri2XxTdJYC8AvrYbc7",
          baseUrl: "https://app.chatwoot.com",
        });
      };

      document.body.appendChild(script);
    } else {
      const existingScript = document.getElementById("chatwoot-script");
      const existingDiv = document.querySelector(".woot--bubble-holder");
      const existingDiv1 = document.querySelector(".woot-widget-holder");

      if (existingScript) {
        existingScript.parentNode.removeChild(existingScript);
      }
      if (existingDiv) {
        existingDiv.style.visibility = "hidden";
      }
      if (existingDiv1) {
        // existingDiv1.parentNode.removeChild(existingDiv1);
      }
    }
  }, [location.pathname]);

  return null; // This component doesn't render anything to the DOM
}

const DashboardLayout = ({ children, visible }) => {
  const authData = useAuth();
  const currUserData = authData?.currUser;
  const dataValue = useData();
  const storedData = dataValue?.dataState;
  const { openAppointmentModal } = storedData;
  const [collapsed, setCollapsed] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  useEffect(() => {
    if (openAppointmentModal) {
      setModalOpen(openAppointmentModal);
    }
  }, [openAppointmentModal]);

  return visible ? (
    <Layout style={{ height: "100vh" }}>
      <Sidebar
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        colorBgContainer={colorBgContainer}
      />
      <Layout>
        <Topbar
          colorBgContainer={colorBgContainer}
          setCollapsed={setCollapsed}
          collapsed={collapsed}
          modalOpen={() => {
            setModalOpen(true);
            dataValue.dispatch({
              type: "SET_STATE",
              payload: {
                key: "openAppointmentModal",
                value: true,
              },
            });
          }}
        />
        <ChatwootScript />
        <Content
          style={{
            margin: "24px 16px",
            marginBottom: "0px",
            padding: 10,
            overflowY: "scroll",
            background: colorBgContainer,
          }}
          className="th-hidden-scrollbar"
        >
          <Outlet />
        </Content>
        {/* <Footer /> */}
      </Layout>

      {modalOpen && (
        <AppointmentModal
          modalVisible={modalOpen}
          closeModal={() => {
            setModalOpen(false);
            dataValue.dispatch({
              type: "SET_STATE",
              payload: {
                key: "openAppointmentModal",
                value: false,
              },
            });
          }}
        />
      )}
      <EqueueModal />
    </Layout>
  ) : (
    <Outlet />
  );
};

export default DashboardLayout;
